import React, { useEffect, useState } from 'react';
import ModalHeader from '../modalHeader';
import Button from '../button';
import { ImSpinner8 } from 'react-icons/im';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'sonner';
import { numberWithCommas } from '../../utils/add_commas';
import { useWebSocket } from '../../../../utils/websocketContext';
import { cartOpen, clearCart } from '../../../../store/cartSlice';
import { useAPI } from '../../../../api/api_context';
import PaymentSuccessModal from '../paymentSuccessModal';
import OrderSuccessModal from '../orderSuccessModal';

const Payment = ({
	handleCloseModal,
	accountNumber,
	accountName,
	expiresAt,
	amountToPay,
	bank,
	reference,
}) => {
	const apiservice = useAPI();
	const storeData = useSelector((state) => state.store);
	const socket = useWebSocket();
	// const navigate = useNavigate();
	const dispatch = useDispatch();

	const [state, setState] = useState({
		isTransactionInProgress: false,
		isTransferConfirmed: false,
		paymentStatus: '',
		amountPaid: 0,
		currency: '',
		isDetailsVisible: false,
		timeRemaining: '',
		isExpired: false,
		pin: [],
		orderId: '',
		customerEmail: '',
		orderTrackingLink: '',
		showOrderSuccessModal: false,
		showPaymentSuccessModal: false,
	});

	const handlePaymentSuccess = (payload) => {
		const {
			paymentDetails,
			customer,
			customId,
			authentication,
			orderLink,
		} = payload;
		const amountPaid =
			paymentDetails.deliveryCost +
			paymentDetails.serviceCharge +
			paymentDetails.totalCartValue;

		setState((prev) => ({
			...prev,
			isTransactionInProgress: false,
			isTransferConfirmed: true,
			paymentStatus: 'success',
			amountPaid,
			orderId: customId,
			customerEmail: customer.email,
			orderTrackingLink: orderLink,
			pin: authentication?.pin
				? String(authentication.pin).split('')
				: [],
			showPaymentSuccessModal: true,
		}));

		startModalTimer();
		dispatch(cartOpen(false));
		dispatch(clearCart());
		// dispatch(resetCustomerDetails());
		toast.success('Your payment was successful!');
	};

	useEffect(() => {
		const interval = setInterval(() => {
			const time = calculateTimeRemaining(expiresAt);
			setState((prev) => ({
				...prev,
				timeRemaining: time,
				isExpired: time === '00:00',
			}));
		}, 1000);

		return () => clearInterval(interval);
	}, [expiresAt]);

	useEffect(() => {
		if (!socket) return;

		const handleMessage = (message) => {
			try {
				const data = JSON.parse(message.data);
				if (
					data.event === 'chargeSuccess' &&
					data.payload?.paymentDetails?.status === 'success'
				) {
					handlePaymentSuccess(data.payload);
				}
			} catch (error) {
				console.error('Error parsing WebSocket message:', error);
			}
		};

		socket.addEventListener('message', handleMessage);
		return () => socket.removeEventListener('message', handleMessage);
	}, [socket]);

	const startModalTimer = () => {
		let timer = 30;
		const countdown = setInterval(() => {
			timer -= 1;
			if (timer <= 0) {
				clearInterval(countdown);
				closePaymentSuccessModal();
				// navigate(`/${storeData.storeIdentifier}`);
				// handleCloseModal();
			}
		}, 1000);
	};

	const calculateTimeRemaining = (expiry) => {
		const timeLeft = new Date(expiry).getTime() - Date.now();
		if (timeLeft <= 0) return '00:00';

		const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
		const seconds = Math.floor((timeLeft / 1000) % 60);
		return `${minutes.toString().padStart(2, '0')}:${seconds
			.toString()
			.padStart(2, '0')}`;
	};

	const verifyPayment = async () => {
		setState((prev) => ({ ...prev, isTransactionInProgress: true }));
		try {
			const response = await apiservice.verifyPayment(reference);
			if (response.paymentDetails?.status === 'success') {
				handlePaymentSuccess(response);
			} else {
				toast.info(`Payment is ${response.paymentDetails?.status}`);
				setState((prev) => ({
					...prev,
					isTransactionInProgress: false,
				}));
			}
		} catch (error) {
			console.error('Error verifying payment:', error);
			toast.error('Error verifying payment.');
			setState((prev) => ({ ...prev, isTransactionInProgress: false }));
		}
	};

	const handleCopyAccountNumber = () => {
		navigator.clipboard.writeText(accountNumber);
		toast('Account Number copied');
	};

	const closePaymentSuccessModal = () => {
		setState((prev) => ({
			...prev,
			showPaymentSuccessModal: false,
			showOrderSuccessModal: true,
		}));
	};

	const renderContent = () => {
		switch (true) {
			case (state.isExpired && !state.showOrderSuccessModal) ||
				(state.isExpired && !state.showPaymentSuccessModal):
				return (
					<>
						<ModalHeader
							payment={true}
							header='Payment Expired'
							onClick={handleCloseModal}
						/>
						<div className='px-6 py-8 flex flex-col items-center'>
							<IoInformationCircleOutline className='text-6xl text-[#CA1616] mb-4' />
							<h2 className='text-[18px] text-primary mb-2'>
								Payment Window Expired
							</h2>
							<p className='text-[15px] text-secondary text-center mb-6'>
								The payment window for this transaction has
								closed. Please initiate a new transaction.
							</p>
							<Button
								label='Close'
								type='primary'
								className='w-full'
								onClick={handleCloseModal}
							/>
						</div>
					</>
				);

			case state.isTransactionInProgress:
				return (
					<>
						<ModalHeader
							payment={true}
							header='Payment method'
							onClick={handleCloseModal}
						/>
						<div className='px-6 flex flex-col'>
							<div className='flex flex-col items-center w-full py-6'>
								<h3 className='text-primary text-center'>
									Transferring NGN amountToPay
									{numberWithCommas(amountToPay)} to{' '}
									{storeData.storeName}
								</h3>
								<div className='flex flex-col items-center gap-3 my-8'>
									<ImSpinner8 className='animate-spin text-2xl text-primary' />
									<p className='text-center text-secondary'>
										Please wait while we confirm your
										payment.
									</p>
								</div>
							</div>
						</div>
					</>
				);
			case state.paymentStatus === 'success' &&
				state.isTransferConfirmed &&
				state.showPaymentSuccessModal:
				return (
					<>
						<PaymentSuccessModal
							onClose={closePaymentSuccessModal}
							amountPaid={state.amountPaid}
						/>
					</>
				);

			case state.showOrderSuccessModal:
				return (
					<>
						<OrderSuccessModal
							pin={state.pin}
							orderId={state.orderId}
							customerEmail={state.customerEmail}
							modalTimer={state.modalTimer}
							orderTrackingLink={state.orderTrackingLink}
						/>
					</>
				);

			default:
				return (
					<>
						<ModalHeader
							payment={true}
							header='Payment method'
							onClick={handleCloseModal}
						/>
						<div className='px-6 flex flex-col '>
							<div className='flex justify-between items-center border-b border-outline gap-4 py-6'>
								<div className='flex items-center gap-3'>
									<i className='fi fi-sr-bank flex items-center justify-center'></i>
									<h2 className='text-[15px] text-primary'>
										Bank transfer
									</h2>
								</div>
								<input
									defaultChecked
									type='radio'
									className='form-radio text-primary focus:ring-0'
								/>
							</div>

							<div className='flex flex-col justify-center items-center'>
								<div className='flex flex-col items-start w-full border-b border-outline gap-1 py-4'>
									<h3 className='text-[13px]  text-secondary'>
										Amount to pay
									</h3>
									<h2 className='text-[21px] font-semibold text-basegreen'>
										NGN {numberWithCommas(amountToPay)}
									</h2>
								</div>
								<p className='text-primary text-[13px] py-4'>
									Transfer to account details below
								</p>
								<div className='flex w-full flex-col gap-4 items-center p-6 bg-[#F5F5F5] border border-[#CECECE] rounded-[12px]'>
									<div className='space-y-1 items-center'>
										<h2 className='text-center text-[15px] text-secondary'>
											{bank}
										</h2>
										<div className='flex gap-2 items-center'>
											<h2 className='text-center text-[20px] text-primary'>
												{accountNumber}
											</h2>
											<button
												className='underline text-basegreen text-center text-[13px] '
												onClick={
													handleCopyAccountNumber
												}>
												Copy
											</button>
										</div>
									</div>
									<div className='space-y-1 items-center'>
										<p className='text-[13px] text-center text-secondary'>
											Account name
										</p>
										<h2 className=' font-semibold text-primary'>
											{accountName}
										</h2>
									</div>
								</div>
							</div>

							<div className='space-y-6 items-center py-8'>
								<Button
									label='I have transferred the money'
									type='primary'
									className='w-full'
									onClick={verifyPayment}
									disabled={state.isTransactionInProgress}
								/>

								<p className=' text-[15px] text-center text-secondary'>
									This account is valid only for this
									transaction and expires in{' '}
									<span className='text-basegreen'>
										{state.timeRemaining}
									</span>
								</p>
							</div>
						</div>
					</>
				);
		}
	};

	return (
		<div
			className='fixed inset-0 bg-primary bg-opacity-90 z-50 flex items-center justify-center modal-overlay'
			//   onClick={handleCloseModal}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className='bg-white rounded-lg max-w-md w-full mx-4 relative overflow-hidden'>
				{renderContent()}
			</div>
		</div>
	);
};

export default Payment;
