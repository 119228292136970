export const getLocationUtility = async (
	getCurrentUserLocation,
	retries = 3,
) => {
	const attemptLocationFetch = (attempt) =>
		new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				async (position) => {
					const { latitude, longitude } = position.coords;

					try {
						const response = await getCurrentUserLocation(
							latitude,
							longitude,
						);
						if (response.status === 200) {
							resolve({
								address: response.data.address,
								deliveryCost: response.data.deliveryCost,
								eta: response.data.eta,
								latitude,
								longitude,
							});
						} else {
							reject(
								new Error('Invalid location data received.'),
							);
						}
					} catch (error) {
						reject(new Error('Error during location fetch.'));
					}
				},
				(error) => {
					if (attempt < retries) {
						setTimeout(
							() => resolve(attemptLocationFetch(attempt + 1)),
							10000,
						);
					} else {
						const errorMessage =
							{
								1: 'User denied location access.',
								2: 'Location information is unavailable.',
								3: 'Geolocation timed out.',
							}[error.code] || 'Geolocation error occurred.';
						reject(new Error(errorMessage));
					}
				},
				{ enableHighAccuracy: true, timeout: 10000, maximumAge: 0 },
			);
		});

	return await attemptLocationFetch(0);
};
