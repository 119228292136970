'use client';

import { useState, useEffect, useCallback } from 'react';
import { numberWithCommas } from './utils/add_commas';
import { useDispatch, useSelector } from 'react-redux';
import AddressModal from './components/addressModal';
import DeliveryDetails from './components/DeliveryDetails';
import CartSummary from './components/cartSummary';
import ScheduleDelivery from './components/scheduleDelivery';
import Switch from './components/switch';
import Button from './components/button';
import { toast } from 'sonner';
import { useAPI } from '../../api/api_context';
import { removeCoupon } from '../../store/couponSlice';
import Coupon from './components/checkout/coupon';
import Layout from '../../Layouts/StoreLayout';
import DeliveryInstructions from './components/DeliveryInstructions';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Payment from './components/checkout/payment';
import { format } from 'date-fns';

const Checkout = ({ isGettingLocation }) => {
	const dispatch = useDispatch();
	const apiService = useAPI();

	const { totalCartValue, products, serviceCharge } = useSelector(
		(state) => state.cart,
	);
	const { selectedCoupon } = useSelector((state) => state.coupon);
	const { deliveryInstructions } = useSelector((state) => state.instruction);
	const userInfo = useSelector((state) => state.userInfo);
	const store = useSelector((state) => state.store);

	const { deliveryCost, userLocation } = useSelector(
		(state) => state.location,
	);
	// console.log('Redux User Details:', userInfo);
	const appliedCoupon = useSelector((state) => state.coupon.appliedCoupon);

	const [state, setState] = useState({
		isOn: false,
		isModalOpen: false,
		isDropdownOpen: false,
		isDetailsModalOpen: false,
		instructionsModalOpen: false,
		couponModalOpen: false,
		isAddressModalOpen: false,
		isPaymentModalOpen: false,
		selectedAddress: '',
		requirePIN: true,
		requireEscrow: true,
		deliveryTiming: 'immediate',
		scheduledDate: null,
		accountNumber: '',
		accountName: '',
		expiresAt: '',
		amountToPay: '',
		bank: '',
		orderCreationLoading: false,
		status: '',
		reference: '',
		customOrderId: '',
	});

	const formattedDate = format(
		new Date(state.scheduledDate),
		'EEEE, MMMM d, h:mm a',
	);
	const endTime = format(
		new Date(state.scheduledDate).setMinutes(
			new Date(state.scheduledDate).getMinutes() + 60,
		),
		'h:mm a',
	);

	const handleCouponModal = () => {
		setState((prev) => ({ ...prev, couponModalOpen: true }));
	};

	const toggleDropdown = () => {
		setState((prev) => ({ ...prev, isDropdownOpen: !prev.isDropdownOpen }));
	};

	const handleRemoveCoupon = async () => {
		if (!selectedCoupon) {
			toast.error('No coupon to remove.');
			return;
		}

		try {
			await apiService.removeCoupon(selectedCoupon.code);
			dispatch(removeCoupon());
			toast.success('Coupon removed successfully.');
		} catch (error) {
			console.error('Error removing coupon:', error);
			toast.error('Failed to remove coupon. Please try again.');
		}
	};

	const handleCreateOrder = async () => {
		// Check if account details already exist and haven't expired
		if (state.accountNumber && state.expiresAt) {
			const expirationTime = new Date(state.expiresAt).getTime();
			const currentTime = new Date().getTime();

			if (expirationTime > currentTime) {
				// If account details exist and are still valid, just open the payment modal
				setState((prev) => ({
					...prev,
					isPaymentModalOpen: true,
				}));
				return;
			}
		}

		if (state.status === 'pending_bank_transfer') {
			setState((prev) => ({
				...prev,
				isPaymentModalOpen: true,
			}));
			return;
		} else if (state.status === '') {
			// If no existing valid account details, proceed with creating a new order
			setState((prev) => ({ ...prev, orderCreationLoading: true }));

			try {
				// Create order payload
				const orderPayload = {
					customer: {
						name: userInfo?.name || '',
						email: userInfo?.email || '',
						phone: userInfo?.phone_number || '',
						address: state.selectedAddress || userLocation || '',
						coordinates: userInfo?.coordinates || [], // You may need to get coordinates from your location state
					},
					products: products.map((product) => ({
						name: product.name,
						price: product.price,
						image: product.image || '',
						quantity: product.quantity,
					})),
					paymentDetails: {
						totalCartValue: totalCartValue || 0,
						deliveryCost: deliveryCost || 0,
						serviceCharge: serviceCharge || 0,
					},
					travelDistance: userInfo?.travelDistance || '',
					eta: userInfo?.eta || '',
					deliveryInstructions: {
						note: deliveryInstructions.note || '',
						alternatePhone:
							deliveryInstructions.alternatePhone || '',
						closestLandmark:
							deliveryInstructions.closestLandmark || '',
					},
					deliverySettings: {
						requirePIN: state.requirePIN,
						requireEscrow: state.requireEscrow,
						deliveryTiming: state.deliveryTiming,
						scheduledDate: state.scheduledDate,
					},
				};

				const response = await apiService.createOrder(
					orderPayload,
					store.storeIdentifier,
				);
				toast.success('Order pending payment');

				if (response.data.order_type === 'single') {
					setState((prev) => ({
						...prev,
						reference: response.data.paymentDetails.reference,
						accountName: response.data.paymentDetails.account_name,
						accountNumber:
							response.data.paymentDetails.account_number,
						bank: response.data.paymentDetails.bank.name,
						expiresAt:
							response.data.paymentDetails.account_expires_at,
						customOrderId: response.data.order.customId,

						amountToPay: response.data.paymentDetails.amount_to_pay,
						isPaymentModalOpen: true,
						status: response?.data?.status,
					}));
				}
			} catch (error) {
				console.error('Error creating order', error);
				toast.error('Failed to create order');
			} finally {
				setState((prev) => ({ ...prev, orderCreationLoading: false }));
			}
		}
	};

	const handleCoupon = () => {
		if (appliedCoupon) {
			handleRemoveCoupon();
		} else {
			handleCouponModal();
		}
	};

	const toggleSwitch = () => {
		setState((prev) => ({ ...prev, isOn: !prev.isOn }));
		!state.isOn &&
			setState((prev) => ({ ...prev, isModalOpen: !prev.isModalOpen }));
		state.isOn &&
			setState((prev) => ({
				...prev,
				deliveryTiming: 'immediate',
				scheduledDate: null,
			}));
	};

	const handleDetailsModal = () => {
		setState((prev) => ({
			...prev,
			isDetailsModalOpen: !prev.isDetailsModalOpen,
		}));
	};
	const handleInstructionsModal = () => {
		setState((prev) => ({
			...prev,
			instructionsModalOpen: !prev.instructionsModalOpen,
		}));
	};

	const cartSize = products.reduce(
		(total, product) => total + product.quantity,
		0,
	);

	useEffect(() => {
		// Automatically use user location if no selected address is set
		if (!state.selectedAddress && userLocation) {
			setState((prev) => ({ ...prev, selectedAddress: userLocation }));
		}
	}, [userLocation, state.selectedAddress]);

	const addDeliverySettings = useCallback(async () => {
		try {
			await apiService.addOrderSettings({
				orderUsesPIN: state.requirePIN,
				orderUsesEscrow: state.requireEscrow,
				deliveryTiming: state.deliveryTiming,
				scheduledDateTime: state.scheduledDate,
			});
		} catch (error) {
			console.error(error);
		}
	}, [
		apiService,
		state.deliveryTiming,
		state.requireEscrow,
		state.requirePIN,
		state.scheduledDate,
	]);

	useEffect(() => {
		addDeliverySettings();
	}, [addDeliverySettings]);
	const getAddressDisplayText = () => {
		if (isGettingLocation) {
			return 'Getting location...';
		}
		if (state.selectedAddress) {
			return state.selectedAddress;
		}
		return 'Add your address';
	};

	const totalCost =
		(totalCartValue || 0) +
		(deliveryCost || 0) +
		(serviceCharge || 0) -
		(appliedCoupon ? appliedCoupon.discountAmount : 0);

	const CustomerDetails = ({ userInfo }) => {
		return (
			<div className='flex flex-col gap-1'>
				<p className=' text-[15px] leading-[20px] text-primary -tracking-[1.5%]'>
					{userInfo?.name}
				</p>
				<p className=' text-[14px] text-secondary -tracking-[1.5%]'>
					{userInfo?.email}
				</p>
				<p className=' text-[14px] text-secondary -tracking-[1.5%]'>
					{userInfo?.phone_number}
				</p>
			</div>
		);
	};
	const DeliveryInfo = ({ deliveryInstructions }) => {
		return (
			<div className='flex flex-col gap-1'>
				{deliveryInstructions.note && (
					<p className=' text-[15px] leading-[20px] text-primary -tracking-[1.5%]'>
						{deliveryInstructions.note}
					</p>
				)}
				{deliveryInstructions.alternatePhone && (
					<p className=' text-[14px] text-secondary -tracking-[1.5%]'>
						{deliveryInstructions.alternatePhone}
					</p>
				)}
				{deliveryInstructions.closestLandmark && (
					<p className=' text-[14px] text-secondary -tracking-[1.5%]'>
						{deliveryInstructions.closestLandmark}
					</p>
				)}
			</div>
		);
	};

	const deliveryDetails = [
		{
			icon: (
				<i
					className='fi fi-sr-member-list flex items-center justify-center'
					style={{ color: '#7B8783' }}></i>
			),
			text:
				userInfo?.name && userInfo?.email && userInfo?.phone_number ? (
					<CustomerDetails userInfo={userInfo} />
				) : (
					'Add your details'
				),
			onClick: handleDetailsModal,
			color:
				userInfo ||
				userInfo?.name ||
				userInfo?.email ||
				userInfo?.phone_number
					? 'text-basegreen'
					: 'text-gray-500',
			action: {
				label:
					userInfo?.name && userInfo?.email && userInfo?.phone_number
						? 'edit'
						: '',
				click: handleDetailsModal,
			},
		},
		{
			icon: (
				<i
					className='fi fi-sr-shipping-fast flex items-center justify-center'
					style={{ color: '#7B8783' }}></i>
			),
			text: getAddressDisplayText(),
			onClick: () => {},
			action: {
				label: 'change',
				click: () =>
					setState((prev) => ({ ...prev, isAddressModalOpen: true })),
			},
			color: userLocation ? 'text-gray-500' : 'text-basegreen',
		},
		{
			icon: (
				<i
					className='fi fi-ss-person-carry-box flex items-center justify-center'
					style={{ color: '#7B8783' }}></i>
			),
			text:
				deliveryInstructions.alternatePhone ||
				deliveryInstructions.closestLandmark ||
				deliveryInstructions.note ? (
					<DeliveryInfo deliveryInstructions={deliveryInstructions} />
				) : (
					'Add Delivery Instructions'
				),
			onClick: handleInstructionsModal,
			color:
				!deliveryInstructions.alternatePhone &&
				!deliveryInstructions.closestLandmark &&
				!deliveryInstructions.note
					? 'text-basegreen'
					: 'text-gray-500',
			action: {
				label:
					deliveryInstructions.alternatePhone &&
					deliveryInstructions.closestLandmark &&
					deliveryInstructions.note
						? 'edit'
						: '',
				click: handleInstructionsModal,
			},
		},
	];
	const roundedTotalCost = Number.parseFloat(totalCost.toFixed(2));
	const payment = [
		{
			title: 'Subtotal',
			text: `NGN ${numberWithCommas(totalCartValue).toLocaleString(2)}`,
		},
		{
			title: 'Delivery Fee',
			text: `NGN ${numberWithCommas(deliveryCost)}`,
		},
		{
			title: 'Service Fee',
			text: `NGN ${numberWithCommas(serviceCharge)}`,
		},

		...(appliedCoupon
			? [
					{
						title: `Coupon (${selectedCoupon?.discountValue}%)`,
						text: `-NGN ${numberWithCommas(
							appliedCoupon.discountAmount,
						)}`,
					},
			  ]
			: []),
		{
			title: 'Total',
			text: `NGN ${numberWithCommas(roundedTotalCost)}`,
		},
	];

	return (
		<Layout
			headerType='checkout'
			background='bg-grey'>
			<div className='h-full w-full flex items-center flex-col justify-center mx-auto'>
				<div className='flex flex-col md:grid grid-cols-12 justify-center gap-6 max-w-[1080px]'>
					<div className=' col-span-7 space-y-4'>
						{/* Delivery Details */}
						<div
							className={`w-full h-auto rounded-lg bg-white flex flex-col`}>
							<h2 className='px-6 py-4 border-b font-semibold border-outline text-primary '>
								Delivery details
							</h2>

							<div className='flex flex-col px-6 pb-4'>
								{deliveryDetails &&
									deliveryDetails.length > 0 &&
									deliveryDetails.map((item, index) => (
										<div key={index}>
											{/* Item Content */}
											<div className='flex justify-between py-6'>
												<div className='flex gap-4 items-center'>
													{typeof item.icon ===
													'string' ? (
														<img
															src={
																item.icon ||
																'/placeholder.svg'
															}
															alt='icon'
															className='w-6 h-6'
														/>
													) : (
														item.icon
													)}
													<span
														onClick={item.onClick}
														className={`text-[14px] -tracking-[1.5%] cursor-pointer ${item.color} pl-2`}>
														{item.text}
													</span>
												</div>
												{item.action && (
													<button
														onClick={
															item.action.click
														}
														className='text-basegreen underline text-[14px] font-medium'>
														{item.action.label}
													</button>
												)}
											</div>
											{/* Divider for all except the last item */}
											{index <
												deliveryDetails.length - 1 && (
												<div className='w-full h-[1px] bg-gray-200' />
											)}
										</div>
									))}
							</div>
						</div>

						{/* Delivery Options */}
						<div
							className={`w-full h-auto rounded-lg bg-white flex flex-col`}>
							<h2 className='px-6 py-4 border-b font-semibold border-outline text-primary'>
								Delivery options
							</h2>
							<div className='pb-[32px] flex flex-col pt-[12px] px-6'>
								<div>
									<div className='flex justify-between py-6 border-b gap-4'>
										<div className='flex flex-col gap-1'>
											<div className='text-[15px] leading-[20px] text-primary -tracking-[1.5%]'>
												Delivery PIN
											</div>
											<div className='font-[400] text-[13px] text-secondary -tracking-[1.5%]'>
												Confirm your order upon delivery
												with a unique PIN to verify it's
												handed to the right person.
											</div>
										</div>
										<input
											type='checkbox'
											checked={state.requirePIN}
											onChange={() =>
												setState((prev) => ({
													...prev,
													requirePIN:
														!prev.requirePIN,
												}))
											}
											className='w-[18px] h-[18px] form-checkbox text-basegreen'
										/>
									</div>
									<div className='flex justify-between py-6 gap-4'>
										<div className='flex flex-col gap-1'>
											<div className='text-[15px] leading-[20px] text-primary -tracking-[1.5%]'>
												Escrow account
											</div>
											<div className='font-[400] text-[13px] text-secondary -tracking-[1.5%]'>
												Secure your payments with escrow
												to ensure funds are released
												only after you receive your
												order.
											</div>
										</div>
										<input
											type='checkbox'
											checked={state.requireEscrow}
											onChange={() =>
												setState((prev) => ({
													...prev,
													requireEscrow:
														!prev.requireEscrow,
												}))
											}
											className='w-[18px] h-[18px] form-checkbox text-basegreen'
										/>
									</div>
								</div>
							</div>
						</div>

						{/* Schedule delivery */}
						<div className={`w-full rounded-lg bg-white`}>
							<div className='flex items-center w-full gap-3 px-6 py-4'>
								<h2 className='font-semibold text-primary '>
									Schedule delivery
								</h2>

								<Switch
									toggleSwitch={toggleSwitch}
									isOn={state.isOn}
								/>
							</div>
							{state.deliveryTiming !== 'immediate' && (
								<div className='flex p-6 border-t border-outline justify-between items-center'>
									<div className='flex gap-4 items-center'>
										<i
											className='fi fi-sr-calendar-clock'
											style={{ color: '#7B8783' }}></i>
										<div>
											{formattedDate} - {endTime}
											{/* {state.scheduledDate} */}
										</div>
									</div>
									<button
										onClick={() =>
											setState((prev) => ({
												...prev,
												isModalOpen: true,
												isOn: true, // Ensure the switch is turned on when opening the modal
											}))
										}
										className='text-basegreen underline text-[14px] font-medium'>
										change
									</button>
								</div>
							)}

							{products.map((product, index) => {
								return (
									<div key={index}>
										{product?.attributes
											?.requireProduction && (
											<div className='flex flex-col px-4 border-t border-outline'>
												<div className='py-6'>
													<div className='flex items-center gap-4 bg-[#F0FFFA] py-[10px] px-6'>
														<div>
															<i
																className='fi fi-rr-info flex items-center justify-center'
																style={{
																	color: '#0B6E4F',
																}}></i>
														</div>
														<div className='flex flex-col gap-1'>
															<h3 className=' text-[15px] leading-[20px] text-primary'>
																Production
																Timeline
															</h3>
															<p className=' text-[13px] leading-[17px] text-secondary'>
																{`Your ${product?.name} will be ready in ${product?.attributes?.productionDays} days`}
															</p>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								);
							})}
						</div>
					</div>
					<div className=' col-span-5 space-y-4'>
						{/* Cart Summary */}
						<div className={`w-full h-auto rounded-lg bg-white `}>
							<div className='flex justify-between items-center w-full gap-4 px-6 py-4 '>
								<h2 className='font-semibold text-primary '>
									Cart Summary ({cartSize} items)
								</h2>

								<div
									onClick={toggleDropdown}
									className='cursor-pointer'>
									{state.isDropdownOpen ? (
										<FaChevronUp />
									) : (
										<FaChevronDown />
									)}
								</div>
							</div>

							<div className=' flex flex-col'>
								{state.isDropdownOpen && (
									<div className='flex flex-col gap-6 bg-white px-6  '>
										{products.map((item, index) => (
											<div
												key={index}
												className={`${
													index ===
													products.length - 1
														? 'border-none'
														: 'border-b border-outline'
												} flex justify-between items-center pb-6 `}>
												<CartSummary item={item} />
											</div>
										))}
									</div>
								)}
							</div>
						</div>

						{/* Payment */}
						<div
							className={`w-full h-auto rounded-lg bg-white flex flex-col`}>
							<div className=''>
								<h2 className='px-6 py-4 border-b border-outline  text-primary '>
									Payment
								</h2>
							</div>

							<div className='flex flex-col pt-[12px] '>
								<div
									className={`border-b border-outline px-6 py-4 flex justify-between items-center w-full`}>
									<div
										className={`text-secondary text-[15px]`}>
										{!appliedCoupon
											? 'Have a promo code?'
											: appliedCoupon.appliedCoupon}
									</div>
									<div
										onClick={handleCoupon}
										className={` text-[14px] text-basegreen underline cursor-pointer`}>
										{!appliedCoupon
											? 'Add Promo Code'
											: 'Remove'}
									</div>
								</div>

								<div className='border-b border-outline p-6 flex flex-col gap-6'>
									{payment.map((item, index) => (
										<div
											key={index}
											className={` ${
												index === payment.length - 1
													? 'pt-[28px]'
													: ''
											} flex justify-between items-center w-full`}>
											<div
												className={` text-[15px] leading-[20px] ${
													index === payment.length - 1
														? 'text-primary  text-[17px] '
														: 'text-secondary'
												}`}>
												{item.title}
											</div>
											<div
												className={`$ text-[15px] leading-[17px] ${
													index === payment.length - 1
														? 'text-primary  text-[17px] leading-[20px]'
														: 'text-secondary'
												}`}>
												{item.text}
											</div>
										</div>
									))}
								</div>

								<div className='flex flex-col p-6 gap-6'>
									<p className=' text-[13px] leading-[20px] text-secondary'>
										By clicking 'Make Payments', you agree
										to Ridefraser’s Sales Terms,
										and arbitration clause, meaning disputes
										related to this purchase will be
										resolved through binding arbitration on
										an individual, non-class basis.
									</p>

									<Button
										type='primary'
										label='Make Payments'
										onClick={handleCreateOrder}
										buttonclassName='w-full'
										loading={state.orderCreationLoading}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{state.isAddressModalOpen && (
				<AddressModal
					isOpen={state.isAddressModalOpen}
					onClose={() =>
						setState((prev) => ({
							...prev,
							isAddressModalOpen: false,
						}))
					}
					onSelectAddress={(address) =>
						setState((prev) => ({
							...prev,
							selectedAddress: address,
							isAddressModalOpen: false,
						}))
					}
					initialAddress={state.selectedAddress || userLocation}
				/>
			)}

			{state.isDetailsModalOpen && (
				<DeliveryDetails
					setIsDetailsModalOpen={(value) =>
						setState((prev) => ({
							...prev,
							isDetailsModalOpen: value,
						}))
					}
				/>
			)}

			{/* Instructions Modal */}
			{state.instructionsModalOpen && (
				<DeliveryInstructions
					setInstructionsModalOpen={(value) =>
						setState((prev) => ({
							...prev,
							instructionsModalOpen: value,
						}))
					}
				/>
			)}

			{state.isModalOpen && (
				<ScheduleDelivery
					setIsModalOpen={(value) =>
						setState((prev) => ({
							...prev,
							isModalOpen: value,
						}))
					}
					setIsOn={(value) =>
						setState((prev) => ({
							...prev,
							isOn: value,
						}))
					}
					setScheduledDate={(datetime) => {
						setState((prev) => ({
							...prev,
							scheduledDate: datetime,
							deliveryTiming: 'scheduled',
						}));
					}}
					scheduledDate={state.scheduledDate}
				/>
			)}

			{state.isPaymentModalOpen && (
				<Payment
					handleCloseModal={() =>
						setState((prev) => ({
							...prev,
							isPaymentModalOpen: false,
						}))
					}
					accountNumber={state.accountNumber}
					accountName={state.accountName}
					expiresAt={state.expiresAt}
					amountToPay={state.amountToPay}
					bank={state.bank}
					reference={state.reference}
					customOrderId={state.customOrderId}
				/>
			)}

			{state.couponModalOpen && (
				<Coupon
					setCouponModalOpen={(value) =>
						setState((prev) => ({
							...prev,
							couponModalOpen: value,
						}))
					}
				/>
			)}
		</Layout>
	);
};

export default Checkout;
